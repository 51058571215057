import React from "react";
import styled from "styled-components";
import mdLogo from "../img/logo-mijndomein.svg";
import mdLogoWhite from "../img/logo-mijndomein-diap.svg";

const StyledLogo = styled.div`
	width: 100vw;
	height: 100vh;
	display: block;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Donut = styled.img`
	width: 80px;
	height: 80px;
	display: inline-block;
	float: left;
	animation: pulse 2s ease infinite;

	@keyframes pulse {
		0% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.2);
		}
	}
`;

function Logo(props) {
	return (
		<StyledLogo diap={props.diap}>
			<Donut src={props.diap === true ? mdLogoWhite : mdLogo} />
		</StyledLogo>
	);
}

export default Logo;
