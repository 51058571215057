import React from "react";
import { NavLink } from "react-router-dom";

function Navigation(props) {
	return (
		<nav>
			<ul>
				<li>
					<NavLink
						exact
						to="/"
						activeClassName="active"
						onClick={props.handleCloseNav}
					>
						Merk
					</NavLink>
				</li>
				<li>
					<NavLink
						exact
						to="/identiteit"
						activeClassName="active"
						onClick={props.handleCloseNav}
					>
						Identiteit
					</NavLink>
				</li>
				<li>
					<NavLink
						exact
						to="/resources"
						activeClassName="active"
						onClick={props.handleCloseNav}
					>
						Resources
					</NavLink>
				</li>
			</ul>
		</nav>
	);
}

export default Navigation;
