import React from "react";
import styled from "styled-components";
import Grid from "../grid/Grid";
import Cell from "../grid/Cell";
import Slider from "react-slick";

const StyledSection = styled.section`
	padding: 48px 0;

	.slick-slider {
		margin-bottom: 70px;
	}
	.slick-dots {
		bottom: -40px;
	}
	.slick-dots li button:before {
		font-size: 18px;
	}
	img {
		width: 100%;
		height: auto;
	}

	.wp-block-columns {
		@media (min-width: 1200px) {
			display: flex;
		}
	}

	.wp-block-column {
		flex: 1;

		@media (min-width: 1200px) {
			margin-right: 16px;
		}

		&:last-child {
			margin-right: 0;

			@media (min-width: 1200px) {
				margin-right: 0;
			}
		}
	}

	.wp-block-image {
		margin: 0;
		margin-bottom: 20px;
	}

	.wp-block-embed-vimeo {
		// https://jsfiddle.net/mcoker/p7q6x4d5/1/
		margin: 0;

		.video-media {
			--video--width: 1920;
			--video--height: 1080;

			position: relative;
			padding-bottom: calc(
				var(--video--height) / var(--video--width) * 100%
			); /* 41.66666667% */
			overflow: hidden;
			max-width: 100%;
			background: black;
		}

		.video-media iframe,
		.video-media object,
		.video-media embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	h1 {
		margin-bottom: 40px;
		font-size: ${(props) => (props.child === true ? "24px" : "36px")};

		@media (min-width: 1200px) {
			font-size: ${(props) => (props.child === true ? "32px" : "48px")};
		}
	}

	h2 {
		font-size: 26px;
		font-weight: normal;
		color: ${(props) => props.theme.gray};
	}

	p {
		color: #878a8e;
	}

	@media (min-width: 1200px) {
		padding: 96px 0;
		background-color: ${(props) => props.background && props.theme.purple};
		color: ${(props) => props.background && props.theme.white};
	}
`;

function Section(props) {
	let content = props.item.content.rendered;
	let galleryItems = props.item.content.rendered.split('<div class="item">');
	galleryItems.forEach((item, index) => {
		let splitItems = item.split("</div>");
		galleryItems[index] = splitItems[0];
	});
	let columns = content.split('<div class="wp-block-columns">');
	let after = "";
	columns.forEach((item, index) => {
		if (index > 0) {
			after += "<div class='wp-block-columns'>" + item + "</div>";
		}
	});
	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		className: "item",
	};

	return (
		<StyledSection id={props.item.slug} child={props.child}>
			<Grid>
				<Cell width={2}></Cell>

				<Cell width={10}>
					<h1>{props.item.title.rendered}</h1>
				</Cell>
			</Grid>
			<Grid>
				<Cell width={2}></Cell>

				<Cell width={10}>
					{/* <div
						dangerouslySetInnerHTML={{
							__html: content,
						}}
					/> */}
					{galleryItems && galleryItems.length > 1 ? (
						<>
							<div
								dangerouslySetInnerHTML={{
									__html: galleryItems[0],
								}}
							/>
							<Slider {...settings}>
								{galleryItems.forEach((item, index) => {
									if (index > 0) {
										return (
											<div key={index}>
												<div
													dangerouslySetInnerHTML={{
														__html: item,
													}}
												/>
											</div>
										);
									}
								})}
							</Slider>
							<div
								dangerouslySetInnerHTML={{
									__html: after,
								}}
							/>
						</>
					) : (
						<div
							dangerouslySetInnerHTML={{
								__html: content,
							}}
						/>
					)}
				</Cell>
			</Grid>
		</StyledSection>
	);
}

export default Section;
