import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { animateScroll as scroll } from "react-scroll";

import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import Cookies from "./components/Cookies";
import Home from "./components/Home";
import Identity from "./components/Identity";
import Resources from "./components/Resources";
import Header from "./components/Header";

const theme = {
	licorice: "#1E2029",
	purple: "#6B2FAD",
	lightPurple: "#9B73C8",
	cyan: "#00DBDB",
	gray: "#646F79",
	mediumGray: "#CCCCCC",
	lightGray: "#EDEDED",
	clay: "#F1F3F4",
	white: "#ffffff",
	border: "rgba(0, 0, 0, 0.12)",
	green: "#00DBDB",
	margin: "16px",
};

export class App extends Component {
	constructor(props) {
		super(props);
		scroll.scrollTo(1);
	}

	render() {
		//
		return (
			<Router basename={process.env.PUBLIC_URL}>
				<ScrollToTop>
					<ThemeProvider theme={theme}>
						<div>
							<Cookies />
							<Header />
							<Route path="/" exact component={Home} />
							<Route
								path="/identiteit"
								exact
								component={Identity}
							/>
							<Route
								path="/resources"
								exact
								component={Resources}
							/>
						</div>
					</ThemeProvider>
				</ScrollToTop>
			</Router>
		);
	}
}

export default App;
