import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
// import { HashLink as Link } from "react-router-hash-link";
import ScrollTo from "react-scroll-into-view";

const StyledSideNav = styled.section`
	position: fixed;
	top: 0;
	margin-top: 56px;
	padding-top: 15px;
	width: 200px;
	height: 100vh;
	background-color: ${(props) => props.theme.white};
	display: none;
	border-right: 1px solid ${(props) => props.theme.clay};
	z-index: 1000;

	@media (min-width: 1200px) {
		display: block;
	}

	.active {
		color: ${(props) => props.theme.purple};
		border-color: ${(props) => props.theme.purple};
	}

	.child {
		padding-left: 30px;
		font-size: 12px;
	}
`;

const NavItem = styled.div`
	border: 0;
	background-color: transparent;
	display: block;
	padding: 5px 15px;
	color: ${(props) => props.theme.licorice};
	font-size: 14px;
	line-height: 1.7;
	border-left: 2px solid transparent;
	outline: none;
	cursor: pointer;
	font-family: circular, sans-serif;

	// arrow
	button {
		border: 0;
		background: none;
		display: inline-block;
		outline: none;
		float: right;
		width: 21px;
		padding: 5px;
		cursor: pointer;
	}
`;

const ButtonOpen = styled.section`
	background-color: ${(props) => props.theme.clay};
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 50px;

	position: fixed;
	bottom: 0;
	left: 0;
	width: 200px;
	cursor: pointer;
`;

const ButtonClosed = styled.section`
	background-color: ${(props) => props.theme.clay};
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 50px;

	position: fixed;
	bottom: 0;
	left: 0;
	width: 50px;
	cursor: pointer;
	display: none;

	@media (min-width: 1200px) {
		display: block;
	}
`;

const Icon = styled(FontAwesomeIcon)`
	margin-right: 20px;
	color: ${(props) => props.theme.purple};
`;

function SideNavItem(props) {
	let item = props.item;
	let currentNavItem = props.currentNavItem;
	let index = props.index;
	const [showChildren, toggleChildren] = useState(true);

	return (
		<>
			<ScrollTo selector={`#${item.slug}`} alignToTop={false}>
				<NavItem className={currentNavItem === index ? "active" : ""}>
					{item.title.rendered}

					{item.children && item.children.length > 0 && (
						<button onClick={() => toggleChildren(!showChildren)}>
							<Icon
								icon={
									showChildren === true
										? faAngleUp
										: faAngleDown
								}
							/>
						</button>
					)}
				</NavItem>
			</ScrollTo>
			{item.children &&
				showChildren &&
				item.children.map((child, childIndex) => {
					return (
						<ScrollTo
							key={childIndex}
							selector={`#${child.slug}`}
							alignToTop={false}
						>
							<NavItem className="child">
								{child.title.rendered}
							</NavItem>
						</ScrollTo>
					);
				})}
		</>
	);
}

function SideNav(props) {
	let currentNavItem = props.currentNavItem;
	let navItems = props.navItems;
	const [showNav, toggleNav] = useState(true);
	return (
		<div>
			{showNav === true && (
				<StyledSideNav>
					<nav>
						{navItems &&
							navItems.map((item, index) => (
								<SideNavItem
									item={item}
									currentNavItem={currentNavItem}
									index={index}
									key={index}
								/>
							))}
					</nav>
					<ButtonOpen onClick={() => toggleNav(!showNav)}>
						<Icon icon={faAngleLeft} />
					</ButtonOpen>
				</StyledSideNav>
			)}
			<ButtonClosed onClick={() => toggleNav(!showNav)}>
				<Icon icon={faAngleRight} />
			</ButtonClosed>
		</div>
	);
}

export default SideNav;
