import React from "react";
import styled from "styled-components";
import Grid from "../grid/Grid";
import Cell from "../grid/Cell";

const StyledHero = styled.section`
	position: relative;
	margin: 0;
	padding: 0;
	background-color: ${(props) => props.theme.purple};

	// @media (min-width: 1024px) {
	//     margin-bottom: 170px;
	// }
`;

const Content = styled.div`
	padding-top: 120px;
	padding-bottom: 90px;

	@media (min-width: 1200px) {
		padding-top: 200px;
		padding-bottom: 140px;
	}
`;

const Title = styled.h1`
	margin: 0;
	color: ${(props) => props.theme.white};
	font-size: 32px;
	line-height: 32px;
	text-align: left;

	@media (min-width: 1200px) {
		font-size: 64px;
		line-height: 64px;
	}
`;

const HeroText = styled.div`
	// padding: 48px 0;
	color: ${(props) => props.theme.white};
	text-align: left;
	font-size: 18px;
	line-height: 24px;

	@media (min-width: 1200px) {
		padding: 0;
		// padding: 48px 0;

		font-size: 22px;
		line-height: 30px;
	}

	p {
		margin: 0;
		margin-bottom: 16px;
	}
`;

function Hero(props) {
	let hero = props.hero;
	return (
		<StyledHero>
			{props.hero != null && (
				<Content>
					<Grid>
						<Cell width={2}></Cell>

						<Cell width={5}>
							<Title>{hero.title.rendered}</Title>
						</Cell>
						<Cell width={5}>
							<HeroText>
								<div
									dangerouslySetInnerHTML={{
										__html: hero.content.rendered,
									}}
								/>
							</HeroText>
						</Cell>
					</Grid>
				</Content>
			)}
		</StyledHero>
	);
}

export default Hero;
