import React, { useState } from "react";
import styled from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import Grid from "../grid/Grid";
import Cell from "../grid/Cell";
//import { Controller, Scene } from "react-scrollmagic";
import TextTicker from "./TextTicker";

import Img from "../img/hero.svg";

const StyledHero = styled.section`
	position: relative;
	margin: 0;
	padding: 0;
	background-color: ${(props) => props.theme.purple};
	color: ${(props) => props.theme.white};
	overflow: hidden;
	width: 100vw;

	min-height: 100vh;

	figure {
		padding: 30px;

		@media (min-width: 1200px) {
			padding: 0;
		}
	}
	img {
		width: 100%;
	}
`;

const Content = styled.div`
	display: flex;
	align-content: center;
	justify-content: center;

	margin-bottom: -50px; // compensate for ticker margin
	padding: 0;
	padding-top: 90px;
	padding-bottom: 90px;
	width: 100vw;

	// min-height: 500px;
	min-height: calc(100vh - 300px); // ticker height + padding (2x 90)

	div {
		// width: 100vw;
		align-self: center;
		// border: 1px solid #fee;
	}

	@media (min-width: 1024px) {
		min-height: calc(100vh - 380px); // ticker height + padding (2x 90)
	}

	@media (min-width: 1600px) {
		min-height: calc(100vh - 530px); // ticker height + padding (2x 90)
	}
`;

const Title = styled.h1`
	margin: 0;
	color: ${(props) => props.theme.white};
	font-size: 32px;
	line-height: 32px;
	text-align: left;

	@media (min-width: 1024px) {
		font-size: 36px;
		line-height: 36px;
	}

	@media (min-width: 1600px) {
		font-size: 48px;
		line-height: 48px;
	}
`;

function Hero(props) {
	const [scrollPos, setScrollPos] = useState(true);

	useScrollPosition(
		({ prevPos, currPos }) => {
			// percentage scrolled
			let percentage = parseInt(
				Math.abs(currPos.y / (window.innerHeight * 0.8)) * 100
			);
			// width of the text
			let tickerWidth = window.innerWidth * 4;
			// new position in pixels
			let scrollPos = -(tickerWidth / 100) * percentage;

			setScrollPos(scrollPos);
		},
		[scrollPos]
	);

	return (
		<StyledHero>
			<Content>
				<div>
					<Grid>
						<Cell width={1}></Cell>

						<Cell width={5}>
							<Title>
								Wie samenwerkt, krijgt alles voor elkaar
							</Title>
							<p>
								Betaalbare hosting voor iedereen. Groene energie
								voor iedereen. Beleggen en investeren voor
								iedereen. Slim autorijden voor iedereen. Alles
								kan. Omdat we het samen doen. Alles wat we doen
								is erop gericht dat jij kunt zeggen: ‘ik regel
								alles gewoon zelf. lk kan dat. Met controle en
								vertrouwen. Alles wat vroeger duur of
								ingewikkeld leek, is nu mijn eigen domein.’
								Simpel. Samen. Slim. Dat is Mijndomein.
							</p>
						</Cell>

						<Cell width={4} left={8}>
							<figure>
								<img src={Img} alt="" />
							</figure>
						</Cell>
					</Grid>
				</div>
			</Content>
			<TextTicker diap>Simpel. Samen. Slim.</TextTicker>
		</StyledHero>
	);
}

export default Hero;
