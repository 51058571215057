import React from "react";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";

const StyledCookieConsent = styled(CookieConsent)`
    position: relative !important;
    max-width: 464px;
    background-color: red;
`;

const Cookies = () => (
    <StyledCookieConsent
        location="bottom"
        buttonText="Ja, accepteren"
        declineButtonText="Nee, bedankt"
        enableDeclineButton={true}
        style={{ background: "#1E2029", textShadow: "2px 2px black" }}
        buttonClasses="cookie-btn"
    >
        <p>
            {" "}
            Mijndomein.nl gebruikt cookies om jou als bezoeker de meest
            relevante informatie te tonen. Accepteer cookies voor optimaal
            gebruiksgemak.&nbsp;
            <a
                href="https://www.mijndomein.nl/over-mijndomein/cookies"
                target="_blank"
                rel="noopener noreferrer"
            >
                Lees meer over onze cookies.
            </a>
        </p>
    </StyledCookieConsent>
);

export default Cookies;
