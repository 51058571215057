import React, { Component } from "react";
import styled from "styled-components";
import TextTicker from "./TextTicker";

import LogoBig from "./LogoBig";

import Grid from "../grid/Grid";
import Cell from "../grid/Cell";

const StyledFooter = styled.footer`
    width: 100%;
    padding-bottom: 30px;
    z-index: 1000;
    background-color: ${(props) => props.theme.purple};
    color: ${(props) => props.theme.white};
    font-size: 18px;   

    p {
        float: right;
        text-align: right;
        line-height: 0;
    }

    @media (min-width: 1200px) {
        padding: 90px 0;

        font-size: 38px;
    }
`;



const Spacer = styled.div`
    height: 30vh;
`;
export class FooterHome extends Component {
    render() {
        return (
            <StyledFooter>
                <TextTicker diap>Doe niet moeilijk. Doe mee.</TextTicker>
                <Spacer>&nbsp;</Spacer>
                <Grid>
                    <Cell width={12}>
                        <LogoBig diap />
                        <p>Samen, Simpel, Slim.</p>

                    </Cell>

                  
                </Grid>
            </StyledFooter>
        );
    }
}

export default FooterHome;
