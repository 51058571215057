import React, { useState } from "react";
import { useRef } from "react";

import styled from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const TickerContainer = styled.div`
	margin-top: 50px;
	margin-bottom: 50px;
	position: relative;
	// border: 1px solid red;
	height: 120px;
	overflow: hidden;

	@media (min-width: 1024px) {
		height: 200px;
	}

	@media (min-width: 1600px) {
		height: 350px;
	}
`;

const StyledTicker = styled.div`
	position: absolute;
	bottom: 0;

	// border: 1px solid;
	padding-left: 100px;
	padding-right: 16px;

	font-size: 100px;
	line-height: 120px;
	font-weight: bold;
	white-space: nowrap;
	color: ${(props) =>
		props.diap === true ? props.theme.white : props.theme.purple};
	transform: translate(${(props) => props.pos}px);

	@media (min-width: 1024px) {
		padding-right: 100px;

		font-size: 180px;
		line-height: 220px;
	}

	@media (min-width: 1600px) {
		font-size: 270px;
		line-height: 350px;
	}
`;

function TextTicker(props) {
	const [scrollPos, setScrollPos] = useState(true);
	const tickerContainerRef = useRef();
	const tickerRef = useRef();

	useScrollPosition(
		({ prevPos, currPos }) => {
			let elementTop = tickerContainerRef.current.offsetTop;
			let elementHeight = tickerRef.current.offsetHeight;
			let elementWidth =
				tickerRef.current.offsetWidth - window.innerWidth; // only scroll invisible part of the text
			let scrolled = Math.abs(currPos.y);
			let windowHeight = window.innerHeight;
			let topMargin = 60;
			let bottomMargin = window.innerHeight * 0.3;

			let scrollHeight =
				windowHeight - elementHeight - topMargin - bottomMargin; //
			let start = elementTop - scrollHeight - bottomMargin;

			let percentage = (scrolled - start) / scrollHeight;

			if (percentage > 1) {
				percentage = 1;
			}

			// new position in pixels
			let scrollPos = -elementWidth * percentage;

			setScrollPos(scrollPos);
		},
		[scrollPos]
	);
	//scrollPos
	return (
		<TickerContainer ref={tickerContainerRef}>
			<StyledTicker pos={scrollPos} diap={props.diap} ref={tickerRef}>
				{props.children}
			</StyledTicker>
		</TickerContainer>
	);
}

export default TextTicker;
