import React, { Component } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import Grid from "../grid/Grid";
import Cell from "../grid/Cell";

const StyledFooter = styled.footer`
	width: 100%;
	padding: 30px 0;
	z-index: 1000;
	background-color: ${(props) => props.theme.licorice};
	color: ${(props) => props.theme.white};
	font-size: 18px;

	@media (min-width: 1200px) {
		padding: 90px 0;
	}
`;

const Highlight = styled.span`
	color: ${(props) => props.theme.cyan};
`;
export class Footer extends Component {
	render() {
		return (
			<StyledFooter>
				<Grid>
					<Cell width={5} left={3}>
						<Logo diap />
					</Cell>

					<Cell width={5}>
						<Highlight>mijndomein.design</Highlight> contains tools,
						resources and guidance to help you build beautiful
						mijndomein products using Donut design components.
					</Cell>
				</Grid>
			</StyledFooter>
		);
	}
}

export default Footer;
