import React from "react";
import styled from "styled-components";

const StyledGrid = styled.div`
    margin: auto 16px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 8px;
    row-gap: 16px;
    width: calc(100% - 32px);
    background: ${props => props.background && props.theme.clay};
    // border: 1px solid;

    @media (min-width: 576px) {
        margin: 0 auto;
        // max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    @media (min-width: 1366px) {
        max-width: 1344px;
    }
`;

function Grid(props) {
    return (
        <StyledGrid background={props.background}>{props.children}</StyledGrid>
    );
}

export default Grid;
