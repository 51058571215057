import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Icon from "@mijndomein/fe-icon";
import Logo from "./Logo";
import Navigation from "./Navigation";

const StyledHeader = styled.header`
	position: fixed;
	width: calc(100% - 24px);
	height: 32px;
	padding: 12px;
	border-bottom: 1px solid ${(props) => props.theme.clay};
	z-index: 1000;
	background-color: ${(props) => props.theme.white};

	ul {
		padding: 0;
	}
`;

const DesktopNav = styled.nav`
	float: right;
	display: none;

	ul {
		margin: 0;
		padding: 0;
	}

	li {
		margin-right: 60px;
		display: inline-block;
	}

	a {
		font-size: 18px;
		color: ${(props) => props.theme.licorice};
		text-decoration: none;
	}

	a.active {
		color: ${(props) => props.theme.purple};
	}

	@media (min-width: 768px) {
		display: block;
	}
`;

const MobileNav = styled.nav`
	position: absolute;
	top: 57px;
	left: 0;
	display: block;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	padding: 16px;

	background-color: ${(props) => props.theme.purple};
	color: ${(props) => props.theme.white};

	li {
		display: block;
	}

	a {
		display: block;
		padding: 0.4em 0;
		font-size: 30px;
		// line-height: 1.4;
	}

	a.active {
		color: ${(props) => props.theme.cyan};
	}

	h3 {
	}

	// @media (min-width: 768px) {
	//     display: none;
	// }
`;

const NavButton = styled.button`
	margin: 4px;
	padding: 0;

	float: right;
	background: none;
	border: 0;

	display: block;
	outline: none;
	cursor: pointer;

	@media (min-width: 768px) {
		display: none;
	}
`;

const StyledIcon = styled(Icon)`
	margin-left: 5px;
	font-size: 24px;
	color: ${(props) => props.theme.purple};
`;

export class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			mobNav: false,
		};

		this.handleToggleMobNav = this.handleToggleMobNav.bind(this);
	}

	handleToggleMobNav() {
		this.setState({
			mobNav: !this.state.mobNav,
		});
	}

	render() {
		return (
			<StyledHeader>
				<Link to="/">
					<Logo />
				</Link>
				<DesktopNav>
					<Navigation />
				</DesktopNav>

				<NavButton onClick={this.handleToggleMobNav}>
					<StyledIcon
						name={this.state.mobNav === true ? "times" : "bars"}
					/>
				</NavButton>
				{this.state.mobNav === true && (
					<MobileNav>
						<Navigation handleCloseNav={this.handleToggleMobNav} />
					</MobileNav>
				)}
			</StyledHeader>
		);
	}
}

export default Header;
