import React from "react";
import styled from "styled-components";
import mdLogo from "../img/logo-mijndomein.svg";
import mdLogoWhite from "../img/logo-mijndomein-diap.svg";

const StyledLogo = styled.div`
	display: inline-block;
	height: ${(props) => (props.large === true ? "80px" : "30px")};
	color: ${(props) =>
		props.diap === true ? props.theme.white : props.theme.purple};

	@media (min-width: 1200px) {
		margin-bottom: 50px;
	}
`;

const Donut = styled.img`
	width: 38px;
	height: 38px;
	display: inline-block;
	float: left;

	@media (min-width: 1200px) {
		width: 80px;
		height: 80px;
	}
`;

const Entity = styled.div`
	display: inline-block;
	margin-top: 0px;
	margin-left: 8px;
	font-size: 58px;
	line-height: 1.2;
	letter-spacing: -0.03em;

	span {
		font-weight: 700;
		display: none;

		@media (min-width: 1200px) {
			display: inline-block;
		}
	}
`;

function LogoBig(props) {
	return (
		<StyledLogo diap={props.diap}>
			<Donut src={props.diap === true ? mdLogoWhite : mdLogo} />
			<Entity>
				<span>mijndomein</span>
			</Entity>
			{/* <StyledIcon name="angle-down" /> */}
		</StyledLogo>
	);
}

export default LogoBig;
