import React from "react";
import styled from "styled-components";

const StyledCell = styled.div`
    grid-column-end: span 12;
    text-align: ${props => props.center === true && "center"};

    @media (min-width: 1200px) {
        grid-column-start: ${props => props.left};
        grid-column-end: ${props => `span ${props.width}`};
        // background-color: #fee;
    }
`;

function Cell(props) {
    return (
        <StyledCell left={props.left} width={props.width} center={props.center}>
            {props.children}
        </StyledCell>
    );
}

export default Cell;
