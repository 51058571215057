import React, { Component } from "react";
import { Helmet } from "react-helmet";
import VisibilitySensor from "react-visibility-sensor";
import axios from "axios";

import Hero from "./Hero";
import Footer from "./Footer";
import Loading from "./Loading";

import SideNav from "./SideNav";
import Section from "./Section";

export class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			hero: null,
			items: null,
			currentNavItem: 0,
			navItems: [
				{ title: "Logo", slug: "logo" },
				{ title: "Merk hiërarchie", slug: "merk-hierarchie" },
				{ title: "Typografie", slug: "typografie" },
			],
		};

		this.getData();

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(isVisible, el) {
		if (isVisible === true) {
			this.setState({ currentNavItem: el });
		}
	}

	getData() {
		let _this = this;

		// get hero content
		axios
			.get("https://services.mijndomein.nl/wp-json/wp/v2/pages?include=7")
			.then(function (response) {
				// handle success
				_this.setState({ hero: response.data[0] });
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			});

		// get sections
		axios
			.get(
				"https://services.mijndomein.nl/wp-json/wp/v2/pages?parent=7&orderby=menu_order&order=asc"
			)
			.then(function (response) {
				// handle success
				let data = response.data;
				_this.setState({ items: data });

				// collect loaded sub sections in array;
				let loading = true;
				let loaded = [];
				// get sub sections
				data.forEach((item, index) => {
					axios
						.get(
							"https://services.mijndomein.nl/wp-json/wp/v2/pages?parent=" +
								item.id +
								"&orderby=menu_order&order=asc"
						)
						.then(function (response) {
							// handle success
							item.children = response.data;
							loaded.push(index);

							// all sub sections loaded?
							if (loaded.length === data.length) {
								loading = false;
							}

							_this.setState({ items: data, loading: loading });
						});
				});
			});
	}

	render() {
		let items = this.state.items;
		return (
			<div className="App">
				{this.state.loading && <Loading />}
				{!this.state.loading && (
					<>
						<SideNav
							active
							navItems={items}
							currentNavItem={this.state.currentNavItem}
						/>

						<Helmet>
							<title>Mijndomein Design</title>
							<meta name="description" content="" />
						</Helmet>
						<Hero hero={this.state.hero} />

						{items &&
							items.map((item, index) => (
								<VisibilitySensor
									key={index}
									onChange={(isVisible) => {
										this.handleChange(isVisible, index);
									}}
									partialVisibility="top"
									// scrollCheck={false}
									// intervalCheck={true}
									offset={{ top: 0 }}
									minTopValue={-10}
									delayedCall={true}
								>
									<>
										<Section item={item} />
										{item.children &&
											item.children.map(
												(child, childIndex) => (
													<Section
														key={childIndex}
														item={child}
														child={true}
													/>
												)
											)}
									</>
								</VisibilitySensor>
							))}

						<Footer />
					</>
				)}
			</div>
		);
	}
}

export default Home;
