import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Grid from "../grid/Grid";
import Cell from "../grid/Cell";
import TextTicker from "./TextTicker";
import HomeHero from "./HomeHero";
import FooterHome from "./FooterHome";
import Img1 from "../img/groene-energie.svg";
import Img2 from "../img/slim-autorijden.svg";
import Img3 from "../img/beleggen.svg";
import Img4 from "../img/img4.jpg";

const StyledSection = styled.section`
    background-color: ${(props) => props.theme.clay};

    h1 {
        font-size: ${(props) => (props.child === true ? "32px" : "48px")};
    }

    h2 {
        font-size: 40px;
        line-height: 40px
        font-weight: bold;
        text-align:center;
        margin-bottom: 0;
    }

    p {
        text-align: center;
        color: #878a8e;
        font-size: 22px;
    }

    figure {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        padding: 0;
        margin-top: 30px;


        @media (min-width: 1200px) {

            padding: 0;
            width: 100%;
            height: 400px;
        }

        img {
            width: 60%;
        }
    }

    .row {
        padding-top: 0;
        padding-bottom: 100px
    }
`;

const StyledSection2 = styled.section`
	padding-top: 100px;
	padding-bottom: 100px;

	p {
		margin-bottom: 75px;
		text-align: center;
		color: ${(props) => props.theme.gray};
		font-size: 24px;
		line-height: 24px;

		@media (min-width: 1200px) {
			font-size: 32px;
			line-height: 32px;
		}
	}
	h2 {
		font-size: 36px;
		line-height: 36px;

		@media (min-width: 1200px) {
			font-size: 53px;
			line-height: 53px;
		}
	}
`;

const StyledSection3 = styled.section`
	height: 66vw;

	figure {
		margin: 0;
		background-image: url(${Img4});
		background-size: cover;
		background-position: center;
		height: 66vw;
		display: flex;
		align-items: flex-end;
	}
	h2 {
		font-size: 36px;
		line-height: 36px;
		color: ${(props) => props.theme.white};

		@media (min-width: 1200px) {
			font-size: 102px;
			line-height: 102px;
		}
	}
`;

export class Home extends Component {
	render() {
		return (
			<div className="App">
				<Helmet>
					<title>Mijndomein design</title>
					<meta name="description" content="" />
				</Helmet>
				<HomeHero />
				<StyledSection>
					<Grid>
						<Cell width={4}>
							<figure>
								{" "}
								<img src={Img1} alt="" />
							</figure>
							<h2>
								Groene
								<br /> energie
							</h2>
							<p>Voor iedereen</p>
						</Cell>
						<Cell width={4}>
							<figure>
								{" "}
								<img src={Img2} alt="" />
							</figure>
							<h2>
								Slim
								<br /> autorijden
							</h2>
							<p>Voor iedereen</p>
						</Cell>

						<Cell width={4}>
							<figure>
								{" "}
								<img src={Img3} alt="" />
							</figure>
							<h2>
								Beleggen &amp;
								<br /> investeren
							</h2>
							<p>Voor iedereen</p>
						</Cell>
					</Grid>
					<TextTicker>Omdat we het samen doen.</TextTicker>
					<div className="row">
						<Grid>
							<Cell width={5}>
								<strong>Samen onafhankelijk</strong>
							</Cell>
							<Cell width={7}>
								Samen slim is het tegenovergestelde van eigen
								dom. Door te delen bereiken we samen meer. Op
								het gebied van online, energie en mobiliteit.
								Met onze klanten en onze partners. Zo zijn we
								samen met MyWheels bijvoorbeeld mobiliteit
								opnieuw aan het uitvinden. En werken we samen
								met EnergyZero om de transitie op het gebied van
								energie te versnellen. Ook werken we graag samen
								met nieuwe partners. Onze pilots vind je op
								Mijndomein Next.
							</Cell>
						</Grid>
					</div>
				</StyledSection>
				<StyledSection2>
					<Grid>
						<Cell width={3}></Cell>
						<Cell width={6}>
							<p>
								Alles wat we doen is erop
								<br /> gericht dat jij kunt zeggen:{" "}
							</p>
							<h2>
								Ik regel alles gewoon zelf. Ik kan dat. Met
								controle en vertrouwen.{" "}
							</h2>

							<h2>
								Alles wat vroeger duur of ingewikkeld leek, is
								nu mijn eigen domein.
							</h2>
						</Cell>
					</Grid>
				</StyledSection2>
				<StyledSection3>
					<figure>
						<Grid>
							<Cell width={12}>
								{" "}
								<h2>Wij zijn er voor ondernemers</h2>
							</Cell>
						</Grid>
					</figure>
				</StyledSection3>
				<FooterHome />
			</div>
		);
	}
}

export default Home;
